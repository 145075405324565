body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-with-logo {
  display: inline-flex;
  align-items: center;
}

.btn {
  display: inline-flex;
  margin: 10px auto;
  padding: 0.75em;
  text-decoration: none;
  color: white;
  background-color: #10a7ff;
  border: 1px;
  border-radius: 5px;
  font-size: 12pt;
  cursor: pointer;
}

.btn:hover {
  background-color: #0c8de4;
}

.btn-cancel {
  background-color: #ff4d4d;
  margin-left: 10px;
}

.btn-cancel:hover {
  background-color: #e63939;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.title-with-logo {
  display: inline-flex;
  align-items: center;
}

.title-text {
  margin-left: 10px;
}

.captcha-wrapper {
  display: flex;
  margin-top: 1em;
  flex-direction: column;
  align-items: center;
}

input, textarea {
  margin: 10px 0.5em;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12pt;
  font-family: sourcecode-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea {
  height: 100px;
  width: 85%;
}

p {
  margin-top: 0;
}

.social-icons {
  border: 2px solid white;
  margin-top: 1em;
  padding: 0.25em;
  border-radius: 10px;
}

.social-icons a {
  margin: 1em;
  font-size: 14pt;
  color: white;
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin: 20px 0;
}

.countdown-item {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.countdown-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #333;
  color: #fff;
  display: flex;
}

@media (max-width: 500px) {
  .countdown-container, .countdown-item {
    background-color: transparent;
  }
}
